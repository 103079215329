import "./style.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import { useInfiniteScroll } from "@vueuse/core";
import { type BaseButtonVariant, type BaseSize, BBadge, BBreadcrumb, BButton, BCard, BCardBody, BCardFooter, BCardHeader, BCol, BCollapse, BContainer, BDropdownItem, BImg, BLink, BModalOrchestrator, type BModalProps, BNav, BNavbar, BNavbarBrand, BNavbarNav, BNavItem, BNavItemDropdown, BNavText, BOffcanvas, BootstrapVueOptions, BOverlay, BPopover, type Breakpoint, BRow, BSpinner, BTable, BToast, BToastOrchestrator, createBootstrap, type PopoverPlacement, Size, type TableField, useBreadcrumb, useModal, useModalController, useToast } from "bootstrap-vue-next";
import { ComponentType, ConfigurationOption, DirectiveType } from "bootstrap-vue-next/dist/src/types";
import { Field, Form, useField, useForm } from "vee-validate";

import QFilterGroup from "./components/q-filter-group/q-filter-group.vue";
import { type Filter, type FilterObject } from "./components/q-filter-group/q-filter-group.vue";
import QFormFieldCheckbox from "./components/q-form-field-checkbox/q-form-field-checkbox.vue";
import QFormFieldColorPicker from "./components/q-form-field-color-picker/q-form-field-color-picker.vue";
import QFormFieldDate from "./components/q-form-field-date/q-form-field-date.vue";
import QFormFieldInput from "./components/q-form-field-input/q-form-field-input.vue";
import QFormFieldInputDuration from "./components/q-form-field-input-duration/q-form-field-input-duration.vue";
import QFormFieldInputNumber from "./components/q-form-field-input-number/q-form-field-input-number.vue";
import QFormFieldInputTags from "./components/q-form-field-input-tags/q-form-field-input-tags.vue";
import QFormFieldMultiselect, { type IOptionMultiselect } from "./components/q-form-field-multiselect/q-form-field-multiselect.vue";
import QFormFieldRadio from "./components/q-form-field-radio/q-form-field-radio.vue";
import { type IOption } from "./components/q-form-field-select/q-form-field-select.vue";
import QFormFieldSelect from "./components/q-form-field-select/q-form-field-select.vue";
import QFormFieldTextArea from "./components/q-form-field-textarea/q-form-field-textarea.vue";
import QFormFieldTextEditor from "./components/q-form-field-texteditor/q-form-field-texteditor.vue";
import QFormFieldTime from "./components/q-form-field-time/q-form-field-time.vue";
import QFormFieldWrapper from "./components/q-form-field-wrapper/q-form-field-wrapper.vue";
import QMenu from "./components/q-menu/q-menu.vue";
import { type MenuItem } from "./components/q-menu/q-menu-item.vue";
import QModal from "./components/q-modal/q-modal.vue";
import QModalConfirmDelete from "./components/q-modal-confirm-delete/q-modal-confirm-delete.vue";
import QModalSuccess from "./components/q-modal-success/q-modal-success.vue";
import QNav from "./components/q-nav/q-nav.vue";
import QNavbar from "./components/q-navbar/q-navbar.vue";
import QPagination, { type QPaginationAlignment } from "./components/q-pagination/q-pagination.vue";
import QProgress from "./components/q-progress/q-progress.vue";
import QStepper from "./components/q-stepper/q-stepper.vue";
import QTable, { type IQTableField, type IQTableItem } from "./components/q-table/q-table.vue";
import QTabs from "./components/q-tabs/q-tabs.vue";
import { type TabItem } from "./components/q-tabs/q-tabs.vue";
import QThumbnail from "./components/q-thumbnail/q-thumbnail.vue";
import QTreeView, { type TreeItem } from "./components/q-tree-view/q-tree-view.vue";
import QTreeViewItem from "./components/q-tree-view/q-tree-view-item/q-tree-view-item.vue";
import useComponentApp from "./utils/component-app";
import * as inputMask from "./utils/input-masks";
import { useBreakpoints, useResponsiveUtilities } from "./utils/responsive";
import useRule, { type AvailableRules } from "./utils/rules";
import { type ColorVariant, type CustomColorVariant } from "./utils/variant";
import useValidationI18n from "./utils/vee-validate-i18n";

/** @deprecated since version 2.0.0 - use createDsy(pluginData?: BootstrapVueOptions) instead */
export function createDsy(value: Readonly<{
	components?: boolean | ConfigurationOption<ComponentType>
	aliases?: Record<string, ComponentType>
	directives?: boolean | ConfigurationOption<DirectiveType>
	plugins?: BootstrapVueOptions
}>);
export function createDsy(pluginData?: Readonly<BootstrapVueOptions>); // FIXME: #56616: [DSy] Discovery createDsy on main boot
export function createDsy(value: object = {}) {
	if ("plugins" in value)
		return createBootstrap(value.plugins as BootstrapVueOptions);
	return createBootstrap(value as BootstrapVueOptions);
}

// Types
export type {
	AvailableRules,
	BaseButtonVariant,
	BaseSize,
	Breakpoint,
	ColorVariant,
	CustomColorVariant,
	Filter,
	FilterObject,
	IOption,
	IOptionMultiselect,
	IQTableField,
	IQTableItem,
	MenuItem,
	PopoverPlacement,
	BModalProps as QModalProps,
	QPaginationAlignment,
	Size,
	TabItem,
	TableField,
	TreeItem
};

// Composables
export {
	inputMask,
	useBreadcrumb,
	useBreakpoints,
	useComponentApp,
	useField,
	useForm,
	useInfiniteScroll,
	useModal,
	useModalController,
	useResponsiveUtilities,
	useRule,
	useToast,
	useValidationI18n
};

export {
	// FIXME: #61674 remove after BNav and BNavbar template implementation
	BNav,
	BNavbar,
	// FIXME: #51563 remove after QTable template implementation
	BTable,
	BBadge as QBadge,
	BBreadcrumb as QBreadcrumb,
	BButton as QButton,
	BCard as QCard,
	BCardBody as QCardBody,
	BCardFooter as QCardFooter,
	BCardHeader as QCardHeader,
	BCol as QCol,
	BCollapse as QCollapse,
	BContainer as QContainer,
	BDropdownItem as QDropdownItem,
	Field as QField,
	QFilterGroup,
	Form as QForm,
	QFormFieldCheckbox,
	QFormFieldColorPicker,
	QFormFieldDate,
	QFormFieldInput,
	QFormFieldInputDuration,
	QFormFieldInputNumber,
	QFormFieldInputTags,
	QFormFieldMultiselect,
	QFormFieldRadio,
	QFormFieldSelect,
	QFormFieldTextArea,
	QFormFieldTextEditor,
	QFormFieldTime,
	// FIXME: #41379 QFormFieldTextEditor
	QFormFieldWrapper,
	BImg as QImg,
	BLink as QLink,
	QMenu,
	QModal,
	QModalConfirmDelete,
	BModalOrchestrator as QModalOrchestrator,
	QModalSuccess,
	QNav,
	QNavbar,
	BNavbarBrand as QNavbarBrand,
	BNavbarNav as QNavbarNav,
	BNavItem as QNavItem,
	BNavItemDropdown as QNavItemDropdown,
	BNavText as QNavText,
	BOffcanvas as QOffcanvas,
	BOverlay as QOverlay,
	QPagination,
	BPopover as QPopover,
	QProgress,
	BRow as QRow,
	BSpinner as QSpinner,
	QStepper,
	QTable,
	QTabs,
	QThumbnail,
	BToast as QToast,
	BToastOrchestrator as QToastOrchestrator,
	QTreeView,
	QTreeViewItem
};
